import Keycloak from "keycloak-js";
import "./styles.scss";

const keycloak = new Keycloak({
  url: "https://auth.kiv-qa.ults.build/",
  realm: "kmb-main",
  clientId: "frontend",
  /* url: "http://localhost:8080/",
  realm: "kmb-main",
  clientId: "kiv_ui_client", */
});


export default keycloak;
